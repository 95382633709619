import GradingIcon from '@mui/icons-material/Grading'
import { BIGMODULE } from 'constants/list-module'

const TrackingManagement = {
  id: 'trackingmanagement',
  icon: <GradingIcon />,
  title: 'Tracking order GTM',
  url: '/tracking-management',
  role: ['*'],
  code: BIGMODULE.ORDER,
  type: 'single',
}

export default TrackingManagement
