import { DELETE, PATCH, POST, PUT } from 'constants/method'
import { transformObjToUrlQuery } from '../utils/common'
import fetchApi from './config'

// Posts
export const getPost = async (id: string) => {
  return fetchApi(`/post/posts/detail/${id}`)
}

export const getPostPinned = async () => {
  return fetchApi(`/post/posts/pinned`)
}

export const createPostPinned = async (data: object) => {
  return fetchApi(`/post/posts/pinned`, data, POST)
}

export const getHomePostPinned = async () => {
  return fetchApi(`/post/posts?home_pin=true&order_by[][home_pin.order]=asc`)
}

export const createHomePostPinned = async (data: object) => {
  return fetchApi(`/post/posts/home-pinned`, data, POST)
}

export const createHotPost = async (data: object) => {
  return fetchApi(`/post/posts/most-view`, data, POST)
}

export const categoriesSetting = async (data: object) => {
  return fetchApi(`/post/categories/bulk-update`, data, PATCH)
}

export const getHotPost = async () => {
  return fetchApi(`/post/posts/most-view`)
}

export const getPosts = async (filter: object) => {
  return fetchApi(`/post/posts${transformObjToUrlQuery(filter)}`)
}

export const createPost = async (data: object) => {
  return fetchApi(`/post/posts`, data, POST)
}

export const updatePost = async (id: string, data: object) => {
  return fetchApi(`/post/posts/${id}`, data, PUT)
}

export const deletePost = async (id: string) => {
  return fetchApi(`/post/posts/${id}`, {}, DELETE)
}

export const exportPost = async (params: any) => {

  return fetchApi(`/post/posts/export${transformObjToUrlQuery(params)}`,)
}

// Categories
export const getCategory = async (id: string) => {
  return fetchApi(`/post/categories/${id}`)
}

export const getCategories = async (filter: object) => {
  return fetchApi(`/post/categories/menu${transformObjToUrlQuery(filter)}`)
}

export const getCategoriesByTitle = async (filter: object) => {
  return fetchApi(`/post/categories${transformObjToUrlQuery(filter)}`)
}

export const createCategory = async (data: object) => {
  return fetchApi(`/post/categories`, data, POST)
}

export const updateCategories = async (data: object) => {
  return fetchApi(`/post/categories/update-items`, data, POST)
}

export const updateCategory = async (id: string, data: object) => {
  return fetchApi(`/post/categories/${id}`, data, PUT)
}

export const deleteCategory = async (id: string, data: object) => {
  return fetchApi(`/post/categories/${id}`, data, DELETE)
}

// Tags
export const getTag = async (id: string) => {
  return fetchApi(`/post/tags/${id}`)
}

export const getTags = async (filter: object) => {
  return fetchApi(`/post/tags/admin${transformObjToUrlQuery(filter)}`)
}

export const createTag = async (data: object) => {
  return fetchApi(`/post/tags`, data, POST)
}

export const updateTag = async (id: string, data: object) => {
  return fetchApi(`/post/tags/${id}`, data, PUT)
}

export const deleteTag = async (id: string, data: object) => {
  return fetchApi(`/post/tags/${id}`, data, DELETE)
}

//----Comments----//
/**
 * Chi tết comment
 * @param data
 * @returns
 */
export const getComment = async (id: string) => {
  return fetchApi(`/post/posts/comments/${id}`)
}

export const getComments = (filter: object) => {
  return fetchApi(`/post/posts/comments${transformObjToUrlQuery(filter)}`)
}

/**
 * Xóa nhiều bình luận 1 lần
 * @param data { "comments": [id1, id2, ..] }
 * @returns
 */
export const bulkDeleteComment = async (data: object) => {
  return fetchApi(`/post/posts/comments`, data, DELETE)
}

/**
 * Duyệt nhiều bình luận 1 lần
 * @param data { "comments": [id1, id2, ..] }
 * @returns
 */
export const bulkApproveComment = async (data: object) => {
  return fetchApi(`/post/posts/comments/approve`, data, PUT)
}

/**
 * Bỏ duyệt nhiều bình luận 1 lần
 * @param data { "comments": [id1, id2, ..] }
 * @returns
 */
export const bulkUnApproveComment = async (data: object) => {
  return fetchApi(`/post/posts/comments/unapproved`, data, PUT)
}

/**
 * Trả lời comment
 * @param data
 * @returns
 */
export const answerComment = async (data: object) => {
  return fetchApi(`/post/posts/answer`, data, POST)
}

/**
 * Xóa nhiều câu trả lời 1 lần
 * @param data { "answers": [id1, id2, ..] }
 * @returns
 */
export const bulkDeleteAnswer = async (data: object) => {
  return fetchApi(`/post/posts/answers`, data, DELETE)
}
