import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const TrackingManagement = Loadable(lazy(() => import('pages/TrackingManagement/Listing/index')))

const TrackingManagementRoutes = [
  {
    path: 'tracking-management',
    element: <TrackingManagement />,
    code: MODULE.ORDER,
  },
]

export default TrackingManagementRoutes
