// project import
import appearance from './appearance'
import Customer from './customer'
import LandingPage from './landing-page'
import OrderManagement from './order-management'
import PopUp from './popup'
import post from './post'
import product from './product'
import RoleManagement from './role-management'
import Setting from './setting'
import Depot from './depot'
import Store from './store'
import StaticPage from './static-page'
import marketing from './marketing'
import ShortLink from './shortLink'
import MediasManagement from './medias-management'
import ShippingFeesManagement from './shipping-fees-management'
import ckcPage from './ckc-page'
import TrackingManagement from './tracking-management'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    post,
    StaticPage,
    product,
    appearance,
    marketing,
    Store,
    Customer,
    LandingPage,
    ckcPage,
    PopUp,
    Setting,
    OrderManagement,
    TrackingManagement,
    Depot,
    ShortLink,
    MediasManagement,
    ShippingFeesManagement,
    RoleManagement,
  ],
}

export default menuItems
